<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Insensitive Munitions" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2"></div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            This group of tests provides data sufficient to determine which
            devices qualify as insensitive munitions for the military.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="Cook-Off Temperature" />
        <Paragraph
          ><p>
            The cook-off temperature is the lowest bar temperature at which a
            5-mg sample is flashed off a melting point bar.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Fast Cook-Off, Jet Fuel or Propane" />
        <Paragraph
          ><p>
            The test article is placed on a non-combustible stand, such as a
            steel grate, and a pool of jet fuel or large propane burner is
            provided. The fuel is ignited and the test article is observed for
            type and degree of reaction.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="Slow Cook-Off" />
        <Paragraph
          ><p>
            This test determines the reactivity of an explosive sample to a
            gradually increasing thermal environment. In addition, the
            temperature at which a reaction occurs may be measured. The test
            sample is subjected to a gradually increasing air temperature at a
            rate of 3.3 °C per hour until a reaction occurs. The test may begin
            with the test item pre-conditioned to 55 °C below the anticipated
            reaction temperature. Measurements of elapse time and temperature
            are recorded and color photographs are taken to help document the
            test conditions. Any cratering or fragmentation is also documented.
            Three trials are required.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Sympathetic Detonation Test" />
        <Paragraph
          ><p>
            Sympathetic detonation is the reaction occurring when the detonation
            of a donor sample initiates detonation in a nearby acceptor sample.
            This test is used to find the minimum distance required to prevent
            sympathetic detonation. The test consists of varying the gap between
            donor and acceptor to find the minimum distance required to prevent
            acceptor detonation. Other sympathetic reactions (explosion or
            initiation and burning) can be determined in the same manner.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Insensitive Munitions",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS is proficient in administering tests that determine whether articles classify as insensitive munitions. These tests include cook-off temperature, slow cook-off, fast cook-off, and sympathetic detonation."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
